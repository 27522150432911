import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"size-bio-panel"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"pt-10"},[_c(VCardTitle,{staticClass:"justify-center flex-column"},[_c('span',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.sizeData.name_en))])]),_c(VCardText,[_c('h2',{staticClass:"text-xl font-weight-semibold mb-2"},[_vm._v(" Details ")]),_c(VDivider),_c(VList,[_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v("Size Name (En):")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.sizeData.name_en))])]),_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v("Size Name (Ar):")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.sizeData.name_ar))])]),_c(VCardActions,{staticClass:"justify-center pt-5"},[_c(VBtn,{staticClass:"me-3",attrs:{"color":"primary"},on:{"click":function($event){_vm.isBioDialogOpen = !_vm.isBioDialogOpen}}},[_vm._v(" Edit ")])],1)],1)],1)],1),_c('size-bio-edit',{attrs:{"is-bio-dialog-open":_vm.isBioDialogOpen,"size-data":_vm.sizeData},on:{"update:isBioDialogOpen":function($event){_vm.isBioDialogOpen=$event},"update:is-bio-dialog-open":function($event){_vm.isBioDialogOpen=$event},"refresh":_vm.updateSizeData}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }