<template>
  <!-- edit profile dialog -->
  <v-dialog
    v-model="isBioDialogOpen"
    max-width="650px"
    @click:outside="$emit('update:is-bio-dialog-open',false)"
  >
    <v-card class="size-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        Edit Size Information
      </v-card-title>
      <v-card-text class="text-center mt-n2">
        Updating Size details will receive a privacy audit.
      </v-card-text>

      <v-card-text class="mt-5">
        <v-form
          ref="form"
          v-model="valid"
          class="multi-col-validation"
          @submit.prevent="onSubmit"
        >
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="sizeDataLocal.name_en"
                :rules="[validators.required]"
                outlined
                dense
                label="Size Name (En)"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="sizeDataLocal.name_ar"
                outlined
                dense
                :rules="[validators.required]"
                label="Size Name (Ar)"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                @click.prevent="onSubmit"
              >
                submit
              </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="$emit('update:is-bio-dialog-open',false)"
              >
                Discard
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { emailValidator, integerValidator, required } from '@core/utils/validation'
import { ref, watch } from '@vue/composition-api'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    sizeData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const isBillingAddress = ref(true)

    const sizeDataLocal = ref({})

    sizeDataLocal.value = JSON.parse(JSON.stringify(props.sizeData))

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const resetSizeData = () => {
      sizeDataLocal.value = JSON.parse(JSON.stringify(sizeDataLocal))
      resetForm()
    }

    const statusOptions = [
      { title: 'Pending', value: 'pending' },
      { title: 'Active', value: 'active' },
      { title: 'Inactive', value: 'inactive' },
    ]
    const languageOptions = ['English', 'Spanish', 'Portuguese', 'Russian', 'French', 'German']

    // on form submit
    const onSubmit = async () => {
      if (valid.value) {
        try {
          const data = await store.dispatch('app-sizes/editSize', sizeDataLocal.value)
          const responseData = data.data.data.size
          emit('refresh', responseData)
          emit('update:is-bio-dialog-open', false)
        } catch (error) {
          console.error(error)
        }
      } else {
        validate()
      }
    }

    watch(
      () => props.isBioDialogOpen,
      () => {
        sizeDataLocal.value = JSON.parse(JSON.stringify(props.sizeData))
      },
    )

    return {
      isBillingAddress,
      statusOptions,
      languageOptions,
      countries,
      sizeDataLocal,
      resetSizeData,
      form,
      valid,
      validate,
      onSubmit,

      // validation
      validators: { required, emailValidator, integerValidator },
    }
  },
}
</script>
