import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"650px"},on:{"click:outside":function($event){return _vm.$emit('update:is-bio-dialog-open',false)}},model:{value:(_vm.isBioDialogOpen),callback:function ($$v) {_vm.isBioDialogOpen=$$v},expression:"isBioDialogOpen"}},[_c(VCard,{staticClass:"size-edit-info pa-sm-10 pa-3"},[_c(VCardTitle,{staticClass:"justify-center text-h5"},[_vm._v(" Edit Size Information ")]),_c(VCardText,{staticClass:"text-center mt-n2"},[_vm._v(" Updating Size details will receive a privacy audit. ")]),_c(VCardText,{staticClass:"mt-5"},[_c(VForm,{ref:"form",staticClass:"multi-col-validation",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"rules":[_vm.validators.required],"outlined":"","dense":"","label":"Size Name (En)"},model:{value:(_vm.sizeDataLocal.name_en),callback:function ($$v) {_vm.$set(_vm.sizeDataLocal, "name_en", $$v)},expression:"sizeDataLocal.name_en"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"outlined":"","dense":"","rules":[_vm.validators.required],"label":"Size Name (Ar)"},model:{value:(_vm.sizeDataLocal.name_ar),callback:function ($$v) {_vm.$set(_vm.sizeDataLocal, "name_ar", $$v)},expression:"sizeDataLocal.name_ar"}})],1),_c(VCol,{staticClass:"d-flex justify-center mt-3",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"me-3",attrs:{"color":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._v(" submit ")]),_c(VBtn,{attrs:{"outlined":"","color":"secondary"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('update:is-bio-dialog-open',false)}}},[_vm._v(" Discard ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }